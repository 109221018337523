import Turbolinks from "turbolinks"
Turbolinks.start()

// Object.assign(Turbolinks.Controller.prototype, {
//   startSubmit(form, action) {
//     if (this.currentVisit) this.currentVisit.cancel()
//     this.currentVisit = new FormVisit(this, form.action, action, new FormData(form))
//     this.currentVisit.start()
//     Turbolinks.dispatch("turbolinks:submit", {data: {form: form}})
//   }
// })
//
// class FormRequest extends Turbolinks.HttpRequest {
//   send(data) {
//     let base
//     if (this.xhr && !this.sent) {
//       this.notifyApplicationBeforeRequestStart()
//       this.setProgress(0)
//       this.xhr.send(data)
//       this.sent = true
//       return typeof (base = this.delegate).requestStarted === "function" ? base.requestStarted() : void 0
//     }
//   }
//
//   createXHR() {
//     this.xhr = new XMLHttpRequest
//     this.xhr.open("POST", this.url)
//     this.xhr.timeout = this.constructor.timeout * 1000
//     this.xhr.setRequestHeader("Accept", "text/html, application/xhtml+xml")
//     this.xhr.setRequestHeader("Turbolinks-Referrer", this.referrer)
//     this.xhr.onprogress = this.requestProgressed
//     this.xhr.onload = this.requestLoaded
//     this.xhr.onerror = this.requestFailed
//     this.xhr.ontimeout = this.requestTimedOut
//     return this.xhr.onabort = this.requestCanceled
//   }
// }
//
// class FormVisit extends Turbolinks.Visit {
//   constructor(controller, location, action, data) {
//     super(controller, location, action)
//     this.data = data
//   }
//
//   issueRequest() {
//     if (this.shouldIssueRequest() && (this.request == null)) {
//       this.progress = 0
//       this.request = new FormRequest(this, this.location, this.referrer)
//       this.appendCSRFToken(this.data)
//       return this.request.send(this.data)
//     }
//   }
//
//   appendCSRFToken(data) {
//     let param, token
//     let metas = document.getElementsByTagName("meta")
//     for (let i = 0; i < metas.length; i++) {
//       let name = metas[i].getAttribute("name")
//       if (name == "csrf-param") param = metas[i].content
//       if (name == "csrf-token") token = metas[i].content
//     }
//     if (param && token) data.append(param, token)
//   }
// }
//
// document.addEventListener("submit", function(event) {
//   event.preventDefault()
//   // Turbolinks.controller = new Turbolinks.Controller
//   // Turbolinks.controller.adapter = new Turbolinks.BrowserAdapter(Turbolinks.controller)
//   // Turbolinks.controller.startSubmit(event.target, "advance")
// })
