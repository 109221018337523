/* Import stylesheets. */
import "stylesheets/application"

import "core-js/es/symbol"
import "core-js/es/map"
import "core-js/es/array/from"
import "core-js/es/symbol/iterator"
import "polyfills/closest"

import "modules/activity-local-time"
import "modules/turbolinks-ujs"

import Rails from "rails-ujs"
Rails.start()
