document.addEventListener("turbolinks:load", () => {
  const locale = "en-GB"
  const elements = document.querySelectorAll("[data-activity-time]")
  let previous = null
  for (const element of elements) {
    const timestamp = new Date(element.dataset.activityTime)

    const year = timestamp.getFullYear()
    element.querySelector(".year").innerText = year

    const date = timestamp.toLocaleDateString(locale, {day: "numeric", month: "short"})
    element.querySelector(".date").innerText = date

    const time = timestamp.toLocaleTimeString(locale, {hour: "2-digit", minute: "2-digit"})
    element.querySelector(".time").innerText = time

    if (date != previous) element.classList.add("first")
    previous = date
  }
})
